import { useState, useEffect } from 'react';

import { LessonsVideosIdsService } from '../services';

const useLessonsVideosIds = () => {
  const [lessonsVideosIds, setLessonsVideosIds] = useState(null);

  useEffect(() => {
    const fetchLessonsVideosIds = async () => {
      try {
        const data = await LessonsVideosIdsService.getLessonsVideosIds();
        setLessonsVideosIds(data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchLessonsVideosIds();
  }, []);

  return lessonsVideosIds;
};

export default useLessonsVideosIds;
