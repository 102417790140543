import { useEffect, useMemo, useState } from 'react';
import { ILesson, ILessonsMenuItem } from 'types';
import { Menu, Layout } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { APP_LANGUAGES_ENUM } from 'config/constants';
import { useLessonsVideosIds } from 'core/hooks';

import LessonContent from './lessonContent';
import lessonsList from './facet_map';

import './style.scss';

type LessonTranslationKeys = APP_LANGUAGES_ENUM.am | APP_LANGUAGES_ENUM.ru | APP_LANGUAGES_ENUM.en | APP_LANGUAGES_ENUM.ku;
const { Sider, Content } = Layout;
const LESSON_NUMBER_DIFF = 1;

const fetchLessonItem = async (url: string) => {
  const response = await fetch(`${url}.json`);
  const data = await response.json();
  return data;
};

function Learning() {
  const lessonsVideosIds = useLessonsVideosIds();
  const [defaultSelectedKeys, setDefaultSelectedKey] = useState<string[]>([]);
  const [lessonContent, setLessonContent] = useState<ILesson | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const lang = searchParams.get('lessonlang') as LessonTranslationKeys;
  const number = Number(searchParams.get('number'));
  const lessonData = lessonsList?.[lang];
  const currentLessonVideosIds = lessonsVideosIds?.[lang];

  const setCurrentLesson = (currentLesson: ILessonsMenuItem) => {
    setSearchParams(`lessonlang=${lang}&number=${currentLesson.number - LESSON_NUMBER_DIFF}`);
  };

  useEffect(() => {
    fetchLessonItem(lessonData[number].url).then(res => {
      setLessonContent({
        ...res,
        YTVideoId: currentLessonVideosIds?.[res.number - LESSON_NUMBER_DIFF],
      });
    });
    setDefaultSelectedKey([lessonData[number].title]);
  }, [currentLessonVideosIds, lessonData, number]);

  return (
    <div className="learning router-container">
      <Layout hasSider className="learning__layout-content">
        {lessonContent ? (
          <Layout className="learning__content">
            <Content style={{ margin: '24px any 16px 0' }}>
              <LessonContent lesson={lessonContent} />
            </Content>
          </Layout>
        ) : null}
        <Sider
          width={320}
          breakpoint="lg"
          collapsedWidth="0"
        >
          <Layout className="learning__menu-wrapper">
            <Menu
              key={`${lang}-${defaultSelectedKeys}`}
              theme="light"
              mode="inline"
              defaultSelectedKeys={defaultSelectedKeys}
            >
              {lessonData?.map(
                lesson => (
                  <Menu.Item
                    key={lesson.title}
                    className="learning__menu-item"
                    onClick={() => setCurrentLesson(lesson)}
                  >
                    {lesson.number}
                    .
                    {lesson.title}
                  </Menu.Item>
                ),
              )}
            </Menu>
          </Layout>
        </Sider>
      </Layout>
    </div>
  );
}

export default Learning;
