import { logError } from 'config/logging';

import {
  YOUTUBE_VIDEOS_IDS_API,
} from '../../config/constants';

import Fetch from './fetch';

const fetchConfigs = {
  headers: { 'Content-Type': 'application/json' },
  credentials: 'include' as RequestCredentials,
};

export const getLessonsVideosIds = async (): Promise<any> => {
  try {
    const response = await Fetch.get(`${YOUTUBE_VIDEOS_IDS_API}`, fetchConfigs);
    return response;
  } catch (error) {
    logError('Error fetching bookmark:', JSON.stringify(error));
    throw error;
  }
};
