import Flex from 'components/common/Flex';
import Container from 'components/common/container';
import Typography from 'antd/es/typography';
import './style.scss';

const { Title } = Typography;

function AccountRemovalInstructions() {
  return (
    <Container>
      <Flex className="account-removal" justifyContent="space-between">
        <section>
          <Title level={5}>1. Click on the icon in the top-right corner of the app to open the menu.</Title>
          <img
            className="account-removal__img"
            src="/images/removeAccount/home-page.png"
            alt="CBJC mobile app hom page screenshot"
          />
        </section>
        <section>
          <Title level={5}>
            2. In the opened menu, locate the Remove Account button. Clicking this button will delete all your data.
            Note: Once you remove your data, it cannot be recovered.
          </Title>
          <img
            className="account-removal__img"
            src="/images/removeAccount/remove-account-menu.png"
            alt="CBJC mobile app account remove menu screenshot"
          />
        </section>
      </Flex>
    </Container>
  );
}

export default AccountRemovalInstructions;
