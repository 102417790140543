import { useEffect, useState, useRef } from 'react';
import YouTube from 'react-youtube';
import { ILesson } from 'types';
import VerseModal from 'components/common/Modals/verseModal';
import { Button } from 'antd';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa6';

import Foreword from './foreword';
import SectionInfo from './sectionInfo';
import Sublessons from './sublessons';
import Conclusion from './conclusion';
import EndNoteInfo from './endnotinfo';

import '../style.scss';

interface ILessonContentProps {
  lesson: ILesson;
}
interface IVerse {
  title: string;
  description: string;
}
function LessonContent({ lesson }: ILessonContentProps) {
  const lessonContentBodyRef = useRef<HTMLDivElement>(null);
  const [lessonContentExpanded, setLessonContentExpanded] = useState<boolean>(false);
  const [currentVerse, setCurrentVerse] = useState<IVerse | undefined>(undefined);
  const [videoActiveMinute, setVideoActiveMinute] = useState(0);
  const [lessonActiveSection, setLessonActiveSection] = useState('');
  const lessonBody: HTMLElement | null = document.getElementById('lesson-content__body');

  const handleVerseLinkClick = (event: any) => {
    const verseKey = decodeURI(event.target.dataset.name);
    if (!lesson.versesLinks[verseKey]) {
      return;
    }

    setCurrentVerse({
      title: verseKey,
      description: lesson.versesLinks[verseKey],
    });
  };

  const handleContentBodyExpand = () => {
    if (lessonContentBodyRef.current) {
      lessonContentBodyRef.current.classList.toggle('lesson-content__expanded');
      setLessonContentExpanded(!lessonContentExpanded);
    }
  };

  useEffect(() => {
    setVideoActiveMinute(0);
    if (lessonBody) {
      lessonBody.scrollTop = 0;
    }
  }, [lesson, lessonBody]);

  useEffect(() => {
    window.location.href = `#${lessonActiveSection}`;
  }, [lessonActiveSection]);

  return (
    <div className="lesson-content">
      {lesson?.YTVideoId
        && (
        <div className="lesson-content__video-wrapper">
          <YouTube
            className="lesson-content__video"
            videoId={lesson?.YTVideoId}
            opts={{
              playerVars: {
                autoplay: videoActiveMinute > 0 ? 1 : 0,
                start: videoActiveMinute,
                rel: 0,
                showinfo: 0,
              },
            }}
          />
        </div>
        )}
      <div
        ref={lessonContentBodyRef}
        id="lesson-content__body"
        className={`
          lesson-content__body
          ${lesson?.YTVideoId && 'lesson-content__body__with-video'}
        `}
      >
        <Button
          onClick={handleContentBodyExpand}
          className="lesson-content__expand-button"
        >
          {lessonContentExpanded ? (
            <FaAngleDown />
          ) : (
            <FaAngleUp />
          )}
        </Button>
        {lesson?.foreword && (
          <Foreword
            text={lesson.foreword.text}
            versesLinks={lesson.versesLinks}
            onVerseLinkClick={handleVerseLinkClick}
          />
        )}
        <SectionInfo
          lessonTitle={lesson?.title}
          title={lesson?.sectionsInfo?.title}
          sections={lesson?.sectionsInfo?.sections}
          sectionsLinks={lesson?.sectionsInfo?.sectionsLinks}
          setVideoActiveMinute={setVideoActiveMinute}
          setLessonActiveSection={setLessonActiveSection}
        />
        <Sublessons
          sectionsLinks={lesson?.sectionsInfo?.sectionsLinks}
          setVideoActiveMinute={setVideoActiveMinute}
          versesLinks={lesson?.versesLinks}
          sublessons={lesson?.subLessons}
          onVerseLinkClick={handleVerseLinkClick}
        />
        <Conclusion
          title={lesson?.conclusion?.title}
          description={lesson?.conclusion?.text}
        />
        {lesson?.endnotinfo && (
          <EndNoteInfo endNotInfo={lesson.endnotinfo} />
        )}
      </div>
      <VerseModal currentVerse={currentVerse} />
    </div>
  );
}

export default LessonContent;
